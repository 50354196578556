<template>
  <div class="smb-feedback">
    <Loader v-if="isLoading" />
    <SmbNotificationBar :is-show="getCurrentSmbCustomer.plan !== 1">
      <template v-slot:notification_text>
        <div class="reels-introducing">
          <strong class="reels-introducing__title">
            Introducing Videos (Reels) - New feature!
          </strong>
          <br />
          <p class="reels-introducing__description">
            From now on, get smart, engaging Reels in your suggested content,
            and effortlessly convert static posts into videos (reels) by
            <strong
              >hovering over the image and clicking Change to video!</strong
            >
          </p>
        </div>
      </template>
    </SmbNotificationBar>
    <SmbRequiredSteps />
    <div class="smb-feedback__wrapper">
      <div
        class="smb-feedback__scheduled-content"
        :class="{
          'without-inst-preview':
            !getCurrentSmbCustomer.ig_page || !previewList.length,
        }"
      >
        <div v-if="scheduledContent.length || posted.length">
          <div v-if="scheduledContent.length">
            <div class="post-header">Scheduled Content</div>
            <PostList
              :posts="scheduledContent.filter((item) => item.type === 'post')"
              :stories="
                scheduledContent.filter((item) => item.type === 'story')
              "
              :postType="'smb-dashboard'"
              :isFacebookPlatform="false"
              :currentCustomer="getCurrentSmbCustomer"
              :isShowSwitch="false"
              isShowRating
              isHeaderClient
              isShowSubmitBtn
              isCanAddACoupon
              isShowEditIconForClient
              isShowIndication
              isShowStoryTitle
              isScheduledContent
              isContentCalendarPage
              :ratingSize="25"
              :publishDateFormat="publishDateFormat"
              @changeDataInPost="pollData"
              @changeDataInStory="pollData"
              @updateSchedule="updateScheduleHandler($event, true)"
              @cancelScheduling="cancelSchedulingHandler($event, true)"
            />
          </div>
          <div v-if="posted.length">
            <div class="post-header">Posted</div>
            <PostList
              :posts="posted.filter((item) => item.type === 'post')"
              :stories="posted.filter((item) => item.type === 'story')"
              :postType="'smb-dashboard'"
              :isFacebookPlatform="false"
              :currentCustomer="getCurrentSmbCustomer"
              :isShowSwitch="false"
              isShowRating
              isHeaderClient
              isShowSubmitBtn
              isCanAddACoupon
              isShowEditIconForClient
              isShowIndication
              isPosted
              isShowStoryTitle
              isContentCalendarPage
              :ratingSize="25"
              isSortedByNewestScheduledForStr
              :publishDateFormat="publishDateFormat"
              @changeDataInPost="pollData"
              @changeDataInStory="pollData"
              @updateSchedule="updateScheduleHandler($event, false)"
              @cancelScheduling="cancelSchedulingHandler($event, false)"
              @howToEditLiveFbPost="howToEditLiveFbPostHandler"
              @howToEditLiveIgPost="howToEditLiveIgPostHandler"
            />
          </div>
        </div>
        <div class="smb-feedback__without-content" v-else>
          <h3>Go ahead and schedule/publish content to see it here</h3>
          <p>
            Once the content is scheduled or published you will start seeing it
            here. If it’s scheduled (and not published) you will be able to
            cancel the scheduling
          </p>
        </div>
        <div
          v-if="getCurrentSmbCustomer.ig_page && !!previewList.length"
          sticky-container
          class="smb-feedback__instagram_preview"
        >
          <InstagramViewGrid v-sticky :preview-list="previewList" />
        </div>
      </div>
    </div>
    <div class="smb-feedback__footer">
      <SmbActionBtn
        text="Back"
        @click="$router.push({ name: 'smb-client' })"
        v-show="isShowBackBtn"
      />
    </div>
    <Referrals
      v-show="isShowReferrals"
      id="referralContainer"
      :code="getCurrentSmbCustomer.stripe_coupon"
      :customer-name="
        getCurrentSmbCustomer.personal_name +
        ' ' +
        (getCurrentSmbCustomer.last_name || getCurrentSmbCustomer.name)
      "
      :referrerEmail="getCurrentSmbCustomer.mail"
      :customerPersonalName="getCurrentSmbCustomer.personal_name"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PostList from "@/components/createPost/PostList";
import Loader from "@/components/global/Loader";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbRequiredSteps from "@/components/smb-client/SmbRequiredSteps.vue";
import helper from "@/mixins/helper";
import InstagramViewGrid from "@/components/global/InstagramViewGrid.vue";
import SmbNotificationBar from "@/components/smb-client/SmbNotificationBar.vue";
import Referrals from "@/components/global/Referrals.vue";
export default {
  name: "SmbContentCalendar",
  mixins: [helper],
  components: {
    Referrals,
    SmbNotificationBar,
    Loader,
    PostList,
    SmbActionBtn,
    SmbRequiredSteps,
    InstagramViewGrid,
  },
  data: () => ({
    scheduledContent: [],
    posted: [],
    isLoading: false,
    publishDateFormat: "MM/DD/YYYY",
    instagramFiles: [],
    isShowReferrals: false,
    isShowBackBtn: false,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("smbPost", ["getContentCalendarAmount"]),
    isFacebookPlatform() {
      return (
        this.getCurrentSmbCustomer.social_platforms.length === 1 &&
        this.getCurrentSmbCustomer.social_platforms.includes("facebook")
      );
    },
    scheduledSortedPosts() {
      let tmpPosts = this.scheduledContent.filter((i) => i.type === "post");
      return [
        ...tmpPosts
          .filter((i) => (i.scheduledForStr ? i : null))
          .sort((a, b) => {
            return a.scheduledForStr < b.scheduledForStr ? 1 : -1;
          }),
        ...tmpPosts
          .filter((i) => (!i.scheduledForStr ? i : null))
          .sort((a, b) => {
            return a.updatedAt > b.updatedAt ? 1 : -1;
          }),
      ];
    },
    previewList() {
      let scheduledPosts = [
        ...this.scheduledSortedPosts.map((i) => {
          if (!i.media)
            return {
              mediaUrl: null,
              isSchedulingPost: true,
              scheduledForStr: i.scheduledForStr,
              isMarkInRed: true,
            };
          const url = this.getMediaUrl(i.media[0]);
          return {
            mediaUrl: url,
            isSchedulingPost: true,
            scheduledForStr: i.scheduledForStr,
            isMarkInRed: true,
          };
        }),
      ];

      return [
        ...scheduledPosts,
        ...this.instagramFiles.map((i) => {
          const url = this.getMediaUrl(i);
          return { mediaUrl: url, isFromInstagram: true };
        }),
      ].filter((i, index) => index < 24);
    },
  },
  async created() {
    this.isLoading = true;

    await this.getArchivePosts();
    await this.getCurrentInstagramPosts();

    this.isShowReferrals = true;
    this.isShowBackBtn = true;
    this.isLoading = false;
  },
  methods: {
    ...mapActions("smbPost", [
      "fetchSmbPosts",
      "actionSmbPosts",
      "actionSmbPosts",
      "fetchAmountsPostsToReview",
    ]),
    ...mapActions("clientDashboard", ["updatePosts"]),
    ...mapActions("mediaLibraryWidget", ["fetchMediaData"]),
    async getArchivePosts() {
      try {
        const data = await this.getPosts({ limit: 60 });
        this.scheduledContent = data
          .filter(
            (item) =>
              item.publishState === "scheduled" ||
              item.publishState === "manual"
          )
          .sort((a, b) => {
            return a.scheduledForStr < b.scheduledForStr ? -1 : 1;
          });
        this.posted = data.filter((item) => item.publishState === "published");
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    async getPosts({ limit = 0, postsType = "archive" }) {
      try {
        return await this.fetchSmbPosts({
          routeName: postsType,
          customerId: this.getCurrentSmbCustomer.id,
          limit: limit,
        });
      } catch (e) {
        throw e;
      }
    },
    async getCurrentInstagramPosts() {
      const params = {
        customerId: this.getCurrentSmbCustomer.id,
        type: 3,
        socialPlatform: "instagram",
        sortBy: 1,
        limit: "100",
      };
      const { items } = await this.fetchMediaData(params);
      this.instagramFiles = items.filter(
        (m) => !m.externalCarousel || m.externalCarousel.includes("_0")
      );
    },
    async cancelSchedulingHandler(data, isScheduledContent = false) {
      try {
        const params = {
          contentCaptionId: data.contentCaptionId,
          customerApprove: "none",
          scheduledForStr: "",
          id: data.id,
        };
        await this.actionSmbPosts(params);
        data.scheduledFor = "";
        data.scheduledForStr = "";

        if (isScheduledContent) {
          this.scheduledContent = this.scheduledContent.filter(
            (i) => i.id !== data.id
          );
        } else {
          this.posted = this.posted.filter((i) => i.id !== data.id);
        }
      } catch (e) {
        console.log(e);
      }
    },

    updateScheduleHandler(postData, isScheduledContent = false) {
      if (isScheduledContent) {
        let searchPost = this.scheduledContent.find(
          (post) => post.id === postData.postId
        );
        searchPost.scheduledForStr = postData.scheduledForStr;
      } else {
        let searchPost = this.posted.find(
          (post) => post.id === postData.postId
        );
        searchPost.scheduledForStr = postData.scheduledForStr;
      }
    },

    focusOnInputHandler(post) {
      if (
        this.rejectedContent &&
        this.rejectedContent.length &&
        this.rejectedContent[0].id === post.id
      ) {
        this.isFocusOnCommentInput = true;
      }
    },
    async pollData(post) {
      await this.updatePosts({
        id: post.id,
        customerRating: post.customerRating,
      });
    },
    howToEditLiveFbPostHandler() {
      window.open(
        "https://www.facebook.com/help/1376303972644600/?helpref=related_articles",
        "_blank"
      );
    },
    howToEditLiveIgPostHandler() {
      window.open("https://help.instagram.com/997924900322403", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.smb-feedback {
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (min-width: 760px) {
    padding-bottom: 65px;
  }
  &__wrapper {
    display: flex;
    flex: 1;
  }
  &__scheduled-content {
    display: grid;
    grid-template-columns: 1fr 400px;
    width: 100%;
    @media screen and (max-width: 760px) {
      grid-template-columns: 1fr;
    }
    &.without-inst-preview {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
  &__instagram_preview {
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  &__without-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 35px;
    }
    p {
      max-width: 624px;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 9% !important;
    @media screen and (min-width: 760px) {
      display: none;
    }
  }
  .post-header {
    margin: 10px 25px 0 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(78, 87, 120, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    color: #1d1d1b;
  }
  ::v-deep {
    .main-container-stories,
    .main-container {
      padding-right: 0;
      .post-list {
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        &__item {
          display: flex;
          flex-direction: column;
          > div {
            display: flex;
            flex: 1;
          }
        }
      }
    }
    .post-wrapper {
      .post-header {
        border-bottom: none;
        font-size: 25px;
      }
    }
    .post {
      &__img {
        &:hover {
          .edit-media {
            display: none !important;
          }
        }
      }
      &__body {
        .post__slide-menu-container {
          display: none !important;
        }
        &:hover {
          .post__slide-menu-container {
            display: none !important;
          }
          .post__slide-menu {
            display: none !important;
          }
        }
      }
    }
    .preview-area__list {
      overflow-y: auto;
      height: calc(100% - 30px);
      &.top-sticky {
        bottom: 0 !important;
      }
    }
  }
}
::v-deep {
  .reels-introducing {
    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    &__description {
      margin-bottom: 0;
      a {
        color: #000;
      }
    }
  }
}
</style>
